<template>
  <div v-if="getLoaded()" class="duck-form form-width-s3 form-center">
    <NotiBar v-if="msg.show" :type="msg.type" :msg="msg.text" />
    
    <form @submit.prevent="saveForm">
      <fieldset class="form-block">
        <div class="h-title">
          <h3>เนื้อหาบทความ</h3>
        </div>

        <div class="form-container">
          <SmallTitle title="เกี่ยวกับเรา" />
          <div class="input">
            <Label text="หัวข้อ" />
            <input type="text" v-model="contentData.meta.about_title">
          </div>
          <div class="input">
            <Label text="รายละเอียด" />
            <textarea v-model="contentData.meta.about_text"></textarea>
          </div>
          <div class="input">
            <Label text="วีดีโอลิ้งค์" />
            <input type="text" v-model="contentData.meta.about_video">
          </div>
          
          
        </div>
      </fieldset>
      <div class="button-block">
        <button type="submit" class="btn btn-update">
          <i class="fa fa-save fa-lg"></i> บันทึกข้อมูล
        </button>
      </div>
    </form>
  </div>
</template>

<script>

import {apiRequest} from '@/utils/axios/axiosInstance.js';
import Label        from '@/components/common/form/Label.vue'
import SmallTitle   from '@/components/common/SmallTitle.vue'

export default {
  components: {
    Label,
    SmallTitle,
  },
  data() {
    return {
      loaded: false,
      msg: {
        show: false,
        type: 'success',
        text: '',
      },
      contentSlug: '',
      contentData: {
        meta: {
          about_title: '',
          about_text: '',
          about_video: '',
        }
      }
    }
  },
  created() {
    this.$store.commit('setPageTitle', 'Edit Services');
    this.$store.commit('setHeadTitle', 'Edit Services');

    this.contentSlug = 'home';
    this.loadPage();
  },
  methods: {
    getLoaded() {
      return !this.$store.state.show_loader;
    },
    loadPage:function() {
      this.$store.commit('setLoaderShow', true);
      apiRequest
        .get('/content/page/'+this.contentSlug)
        .then( (res) => {
          console.log(res.data);
          // this.contentData = res.data;
          
          this.$store.commit('setLoaderShow', false);
        });
    },
    saveForm:function() {
      this.$store.commit('setLoaderShow', true);
      
      // apiRequest
      //   .post('/content/save-post', this.contentData)
      //   .then( (res) => { 
      //     console.log(res.data);
      //     this.msg.show = true;
      //     if(res.data.status==200) {
      //       this.msg.text = 'Save data complete';
      //     }else{
      //       this.msg.type = 'error';
      //       this.msg.text = 'Save data fail. Please, try again.';
      //     }
      //     this.$store.commit('setLoaderShow', false);
      //   });
      // e.preventDefault();
    }
  }
}
</script>